import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import AppConsts from '../../appconst';
import axiosInstance from '../../auth/AxiosInstance';
import { getLoggedInUserId, readToken } from '../../apputils';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const orderSave = createAsyncThunk(
  "orderSave",
  async (data, thunkApi) => {
    try {
      const response = await axiosInstance.post(
        `${AppConsts.apiBaseUrl}/Order/Save`,
        {...data}, 
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        });
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const verifySignature = createAsyncThunk(
  "verifySignature",
  async (data, thunkApi) => {
    try {
      const response = await axiosInstance.post(
        `${AppConsts.apiBaseUrl}/Order/Razorpay/VerifySignature`,
        {razorpayPaymentId: data.razorpay_payment_id, razorpaySignature: data.razorpay_signature, razorpayOrderId: data.razorpay_order_id}, 
        {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + localStorage.getItem("accessToken")
          },
        });
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const onPaymentFailure = createAsyncThunk(
  "onPaymentFailure",
  async (data, thunkApi) => {
    try {
      const response = await axiosInstance.post(
        `${AppConsts.apiBaseUrl}/Order/Razorpay/Payment/Failure`,
        {razorpayPaymentId: data.error.metadata.payment_id,  razorpayOrderId: data.error.metadata.order_id, description: data.error.description, step: data.error.step, reason: data.error.reason }, 
        {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + localStorage.getItem("accessToken")
          },
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const onTransactionStatus = createAsyncThunk(
  "onTransactionStatus",
  async (data, thunkApi) => {
    try {
      const response = await axiosInstance.post(
        `${AppConsts.apiBaseUrl}/Order/Transaction/Status`,
        {...data,companyId:localStorage.getItem('companyId')}, 
        {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + localStorage.getItem("accessToken")
          },
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchOrderList = createAsyncThunk(
  "fetchOrderList",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get(`/Order/GetOrders`,{
       headers: {
        "Accept": "application/json",
        "Authorization": 'Bearer ' + readToken(),
      },
      });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchOrderDetails = createAsyncThunk(
  "fetchOrderDetails",
  async (data, thunkApi) => {
    try {
      const response = await axiosInstance.get(
        `${AppConsts.apiBaseUrl}/Order/GetOrderDetails/orderId/${data}`, {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          }
        });
        return { ...response.data, productDetails: JSON.parse(response.data.productDetails), billingAddress: JSON.parse(response.data.billingAddress), shippingAddress: JSON.parse(response.data.shippingAddress)};
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const checkoutSlice = createSlice({
    name: "checkout",
    initialState: {
        checkoutProducts :[],
        paymentPrefills: [],
        orderList: [],
        orderDetails: [],
    },
    extraReducers: {
      [orderSave.fulfilled]: (state, action) => {
        // state.loading = false
        state.paymentPrefills = action.payload
      },
      [fetchOrderList.fulfilled]: (state, action) => {
        state.loading = false
        state.orderList = action.payload
      },
      [fetchOrderDetails.fulfilled]: (state, action) => {
        state.loading = false
        state.orderDetails = action.payload
      },
    },
    reducers: {
        setCheckoutProducts(state, action) {
            state.checkoutProducts = action.payload;
        },
        setPaymentPrefills(state, action) {
          state.paymentPrefills = action.payload;
      }
    },
});

export const thunks = {
  orderSave, verifySignature, onPaymentFailure, fetchOrderList, fetchOrderDetails
};
export const { setCheckoutProducts, setPaymentPrefills } = checkoutSlice.actions;
export default checkoutSlice.reducer;
