import axios from 'axios';
import AppConsts from '../../appconst';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const ThemeSetupList = createAsyncThunk(
  "/theme/setup-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/ThemeSetup/GetLiveThemeSetup`,
        {
          params: {
            CompanyId: localStorage.getItem('companyId'), 
          },
          headers: { "Accept": "application/json" }
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UiSetupList = createAsyncThunk(
  "/theme/Uisetup-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/ThemeSetup/GetUiSetup`,
        {
          params: {
            CompanyId: localStorage.getItem('companyId'), 
          },
          headers: { "Accept": "application/json" }
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const ThemeSetupSlice = createSlice({
  name: 'themeSetup',
  initialState: {
    loading: false,
    themeSetups: [],
    themeSetup: null,
    uiSetup: null,
  },
  reducers: {},
  extraReducers: builder => {
    // list
    builder.addCase(ThemeSetupList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ThemeSetupList.fulfilled, (state, action) => {
      state.loading = false;
      state.themeSetups = action.payload;
    });
    builder.addCase(ThemeSetupList.rejected, (state, action) => {
      state.loading = false;
      state.themeSetups = [];
    });

    builder.addCase(UiSetupList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(UiSetupList.fulfilled, (state, action) => {
      state.loading = false;
      state.uiSetup = action.payload;
    });
    builder.addCase(UiSetupList.rejected, (state, action) => {
      state.loading = false;
      state.uiSetup = null;
    });
  }
})

export const thunks = {
  ThemeSetupList,
    UiSetupList
};

export default ThemeSetupSlice.reducer;
