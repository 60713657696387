import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import clsx from "clsx";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";

import { UiSetupList } from "../../store/slices/theme-setup-slice";

const HeaderTop = ({ borderStyle }) => {
  const currency = useSelector((state) => state.currency);
  const { uiSetup } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UiSetupList());
  }, []);
  return (
    <div className={`header-top-wap ${borderStyle === "fluid-border" ? "border-bottom" : ""}`} >
      {uiSetup && uiSetup.announcementBarText && (
        <marquee width="100%" direction="left" className="marquee-text" style = {{ color: uiSetup?.announcementBarTextColor || "#ffffff", backgroundColor: uiSetup?.announcementBarBgColor || "#000000", }}>
          {uiSetup?.announcementBarText || ""} 
        </marquee>
      )}
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string
};

export default HeaderTop;
