import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../../auth/AxiosInstance';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import AppConsts from '../../appconst';
// import { getLoggedInUserId } from '../../apputils';
import { getLoggedInUserId, readToken } from '../../apputils';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');


export const FetchUsersDetails = createAsyncThunk(
  "FetchUsersDetails",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get(
        `${AppConsts.apiBaseUrl}/Users/GetUsersDetailsById`,
        {
          headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

const userSlice = createSlice({
  name: "userdetail",
  initialState: {
    userDetailsById: [],
    loading: false,
    error: null,
  },
  extraReducers: {
    [FetchUsersDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [FetchUsersDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetailsById = action.payload;
    },
    [FetchUsersDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  reducers: {
    // setUserDetailsId(state, action) {
    //   state.userDetailsId = action.payload;
    // },
  },
});

export const thunks = {
  FetchUsersDetails,
};

export default userSlice.reducer;
